import React from "react";
// import "./Layout.css";

// Pass the child props
export default function VBCards() {
  return (
    <section id="services" className="menu section">

      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p><span>Check Our</span> <span className="description-title">Service Cards</span></p>
      </div>

      <div className="container">

        <ul className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">

          <li className="nav-item">
            <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#menu-gift">
              <h4>Digital Gift Cards</h4>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-physical">
              <h4>Physical</h4>
            </a>

          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-corporate">
              <h4>Corporate</h4>
            </a>
          </li>
        </ul>

        <div className="tab-content" data-aos="fade-up" data-aos-delay="200">

          <div className="tab-pane fade active show" id="menu-gift">

            <div className="tab-header text-center">
              <h3>Digital Gift Cards</h3>
            </div>

            <div className="row gy-5">

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Digital Gift Card Type 1</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 1000
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Digital Gift Card Type 2</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 2000
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Digital Gift Card Type 3</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 2500
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              

            </div>
          </div>
          <div className="tab-pane fade" id="menu-physical">

            <div className="tab-header text-center">
              <h3>Physical Cards</h3>
            </div>

            <div className="row gy-5">

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Physical Card Type 1</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 100
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Physical Card Type 2</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 200
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Physical Card Type 3</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 1000
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              

            </div>
          </div>
          <div className="tab-pane fade" id="menu-corporate">

            <div className="tab-header text-center">
              <h3>Corporate Cards</h3>
            </div>

            <div className="row gy-5">

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Corporate Card Type 1</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 500
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Corporate Card Type 2</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 1000
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              <div className="col-lg-4 menu-item">
                <a className="glightbox"><img src="assets/img/vb-logo.png" className="menu-img img-fluid" alt="" /></a>
                <h4>Corporate Card Type 3</h4>
                <p className="ingredients">
                  description about card
                </p>
                <p className="price buy-btn">
                  ₹ 2500
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="index.html#book-a-table">Buy</a>
                </p>

              </div>

              

            </div>
          </div>

        </div>

      </div>

    </section>

  
  );
}

