import React from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
// import './App.css';
import Layout from '../../components/Layout/Layout';

function Privacy() {
  return (
    <Layout>
        <section id="about" className="about section">

            <div className="container section-title" data-aos="fade-up">
            <h1>Privacy Policy<br /></h1>
            </div>

            <div className="container">

                <div className="row gy-4 content">
                    <p className="fst-italic">
                    Voucher Buy ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [your website URL] and use our services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
                    </p>
                    <h2>Information We Collect</h2>
                    <p className="fst-italic">
                    We may collect and process the following data about you:
                    </p>
                    <ul>
                    <li> <span><b>Personal Information:</b> This includes your name, email address, phone number, and payment information when you purchase a gift card.</span></li>
                    <li> <span><b>Usage Data:</b>  Information about your interactions with our website, such as IP addresses, browser type, pages visited, and the dates and times of your visits.</span></li>
                    <li> <span><b>Transaction Data:</b> Details about transactions you carry out through our site and the fulfillment of your orders.</span></li>
                    </ul>
                    <h2>How We Use Your Information</h2>
                    <p className="fst-italic">
                    We use the information we collect in the following ways:
                    </p>
                    <ul>

                    <li> <span><b>To Provide Services:</b> To process your purchases, deliver digital gift cards, and manage your account.</span></li>
                    <li> <span><b>To Communicate:</b>  To send you updates, promotional materials, and respond to your inquiries.</span></li>
                    <li> <span><b>To Improve Our Services:</b> To analyze how our site is used, enhance user experience, and develop new features.</span></li>
                    </ul>
                    <h2>How We Share Your Information</h2>
                    <p className="fst-italic">We may share your information with third parties only in the ways described in this Privacy Policy:</p>
                    <ul>
                    <li> <span><b>Service Providers:</b> We may share your data with third-party service providers who assist us in providing our services, such as payment processors and email delivery services.</span></li>
                    <li> <span><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</span></li>
                    </ul>
                    <h2>Data Security</h2>
                    <p className="fst-italic">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                    <h2>Your Data Protection Rights</h2>
                    <p className="fst-italic">Depending on your location, you may have the following rights regarding your personal data: 9. Access: The right to request copies of your personal data.</p>
                    <ul>
                    <li> <span><b>Rectification:</b> The right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</span></li>
                    <li> <span><b>Erasure:</b> The right to request that we erase your personal data, under certain conditions. </span></li>
                    <li> <span><b>Restriction:</b> The right to request that we restrict the processing of your personal data, under certain conditions.</span></li>
                    <li> <span><b>Objection:</b> The right to object to our processing of your personal data, under certain conditions.</span></li>
                    <li> <span><b>Portability:</b> The right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</span></li>
                    </ul>
                    <h2>Changes to This Privacy Policy</h2>
                    <p className="fst-italic">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                    <h2>Contact Us</h2>
                    <p className="fst-italic">If you have any questions about this Privacy Policy, please contact us at:</p>
                    <ul>
                    <li> <span><b>Email:</b> support@voucherbuysoftware.com </span></li>
                    <li> <span><b>Phone:</b> +1 5589 55488 55</span></li>
                    <li> <span><b>Address:</b> 3-225/SH/501, Sterling Heights, Kavuri Hills, Guttalabegumpet, Madhapur, Hyderabad, Telangana – 500081</span></li>
                    </ul>
                    <p className="fst-italic">By using our website and services, you agree to the terms of this Privacy Policy. Thank you for choosing Voucher Buy</p>
                </div>
                


            </div>

        </section>
    </Layout>
    
  );
}

export default Privacy;
