import React from "react";
// import "./Layout.css";

// Pass the child props
export default function Footer() {
  return (
    <footer id="footer" className="footer">

    <div className="container">
      <div className="row gy-3">
        <div className="col-lg-3 col-md-6 d-flex">
          <i className="bi bi-geo-alt icon"></i>
          <div className="address">
            <h4>Address</h4>
            <p>3-225/SH/501, Sterling Heights, Kavuri Hills, Guttalabegumpet, </p>
            <p>Madhapur, Hyderabad, Telangana – 500081</p>
            <p></p>
          </div>

        </div>

        <div className="col-lg-3 col-md-6 d-flex">
          <i className="bi bi-telephone icon"></i>
          <div>
            <h4>Contact</h4>
            <p>
              <strong>Phone:</strong> <span>+1 5589 55488 55</span><br />
              <strong>Email:</strong> <span>support@voucherbuysoftware.com</span><br />
            </p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 d-flex">
          <i className="bi bi-clock icon"></i>
          <div>
            <h4>Opening Hours</h4>
            <p>
              <strong>Mon-Sun:</strong> <span>24/7</span><br />
              {/* <strong>Sunday</strong>: <span>Closed</span> */}
            </p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <h4>Follow Us</h4>
          <div className="social-links d-flex">
            <a href="#" className="twitter"><i className="bi bi-twitter-x"></i></a>
            <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
            <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
            <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
          </div>
        </div>

      </div>
    </div>

    <div className="container copyright text-center mt-4">
      <p>© <span>Copyright</span> <strong className="px-1 sitename">Yummy</strong> <span>All Rights Reserved</span></p>
      <div className="credits">
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  </footer>

  
  );
}