import React from "react";
// import "./Layout.css";

// Pass the child props
export default function Chooseus() {
  return (
    <section id="about" className="about section">
      <div className="container section-title" data-aos="fade-up">
        <h1>Why Choose us?</h1>
      </div>

      <div className="container">

        <div className="row gy-4">
          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="250">
            <div className="content ps-0 ps-lg-5">
              <ul>
                <li> <span><b>Convenience and Flexibility:</b> We understand that everyone has unique preferences. Our gift cards provide the flexibility for recipients to choose their perfect gift, making every occasion special.</span></li>
                <li> <span><b>Customer-Centric Approach:</b> Your satisfaction is our top priority. We strive to offer exceptional service and a seamless shopping experience, ensuring that you and your loved ones are always happy.</span></li>
                <li> <span><b>Reliability and Trust:</b> With a focus on secure transactions and reliable service, [Your Company Name] is a trusted name in the gift card industry. We are committed to maintaining the highest standards of quality and security.</span></li>
              </ul>
              <h2>Join Our Community</h2>
              <p className="fst-italic">
              Join the growing community of satisfied customers who have made [Your Company Name] their go-to choice for gift cards. Whether you're celebrating a birthday, anniversary, holiday, or just wanting to show you care, we have the perfect solution for you.
              </p>
              <p className="fst-italic">
                Thank you for choosing “Voucher Buy”. We look forward to helping you spread joy and happiness through the power of choice.
              </p>
            </div>
          </div>
        </div>

      </div>

    </section>

  
  );
}


