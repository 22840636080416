import React from "react";
// import "./Layout.css";

// Pass the child props
export default function About() {
  return (
    <section id="about" className="about section">
      <div className="container section-title" data-aos="fade-up">
        <h1>About Us</h1>
      </div>

      <div className="container">

        <div className="row gy-4">
          <div className="col-lg-7" data-aos="fade-up" data-aos-delay="100">
            <img src="assets/img/vb-logo.png" className="img-fluid mb-4" alt="" />
            <div className="book-a-table">
              <h3>To get Card</h3>
              <p>+1 5589 55488 55</p>
              <p>support@voucherbuysoftware.com</p>
            </div>
          </div>
          <div className="col-lg-5" data-aos="fade-up" data-aos-delay="250">
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
              At Voucher Buy, our mission is to make gifting easy, enjoyable, and meaningful. We believe that the best gifts are the ones that offer freedom of choice. Our gift cards empower recipients to choose exactly what they want, ensuring every gift is a perfect fit.
              </p>
              <h2>What we Offer</h2>
              <ul>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Diverse Selection:</b> Choose from a variety of Amazon and Flipkart gift cards, suitable for all occasions.</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Instant Delivery:</b> Enjoy the convenience of digital gift cards that are delivered instantly.</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Secure Transactions:</b> Shop with confidence, knowing that our payment processes are safe and secure.</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>24/7 Support:</b>  Our dedicated customer support team is available around the clock to assist you with any inquiries.</span></li>
              </ul>
              <h2>Our Values</h2>
              <ul>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Customer Focus:</b> We put our customers first, striving to meet their needs and exceed their expectations with every interaction.</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Quality Assurance:</b> We partner with trusted brands to ensure our gift cards are of the highest quality and reliability.</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Integrity:</b> We conduct our business with honesty and transparency, building trust with our customers and partners.</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span><b>Innovation:</b> We continuously seek new and creative ways to enhance our products and services, staying ahead of industry trends.</span></li>
              </ul>
            </div>
          </div>
        </div>

      </div>

    </section>

  
  );
}


