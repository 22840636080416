import React,{useEffect} from "react";
import "./Layout.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Importing all created components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

// Pass the child props
export default function Layout({ children }) {
    useEffect(() => {
        AOS.init();
    }, [])
  return (
    <div>
      <Header />
      <main className="main">
        <div className="App">
          {children}
        </div>
      </main>
      <Footer /> 
      <script src="%PUBLIC_URL%/assets/js/main.js"></script>
    </div>
  );
}