import React from "react";
import { useNavigate, useMatch } from "react-router-dom";
// import "./Layout.css";

// Pass the child props
export default function Header() {
  const isHome = useMatch({path: '/', end: true})
  const navigate = useNavigate();
  const navigateToRoute = (routeName) => {
    console.log("welcome to navigation")

    navigate('/'+routeName)
  }
  const navigateToHomeRoute = (routeName) => {
    console.log("welcome to home navigation")
    if(isHome) {
      window.location.replace("/#"+routeName)
    } else {
      navigate('/')
    }
    
  }
  
  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
    <div className="container position-relative d-flex align-items-center justify-content-between">

      <a href="index.html" className="logo d-flex align-items-center me-auto me-xl-0">
        <img src="assets/img/vb-logo.png" alt="" />
        {/*<h1 className="sitename">Yummy</h1>
        <span>.</span>*/}
      </a>

      <nav id="navmenu" className="navmenu">
        <ul>
          <li><a onClick={() => navigateToHomeRoute('hero')} className="active">Home<br /></a></li>
          <li><a onClick={() => navigateToHomeRoute('about')}>About</a></li>
          <li><a onClick={() => navigateToHomeRoute('services')}>Services</a></li>
          <li><a onClick={() => navigateToRoute('terms')}>Terms</a></li>
          <li><a onClick={() => navigateToRoute('privacy')}>Privacy</a></li>
          {/*<li className="dropdown"><a href="#"><span>Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
            <ul>
              <li><a href="#">Dropdown 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="#">Deep Dropdown 1</a></li>
                  <li><a href="#">Deep Dropdown 2</a></li>
                  <li><a href="#">Deep Dropdown 3</a></li>
                  <li><a href="#">Deep Dropdown 4</a></li>
                  <li><a href="#">Deep Dropdown 5</a></li>
                </ul>
              </li>
              <li><a href="#">Dropdown 2</a></li>
              <li><a href="#">Dropdown 3</a></li>
              <li><a href="#">Dropdown 4</a></li>
            </ul>
          </li>*/}
          <li><a onClick={() => navigateToHomeRoute('contact')}>Contact</a></li>
        </ul>
        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>

      <a className="btn-getstarted" href="index.html#book-a-table">Get Card</a>

    </div>
  </header>

  
  );
}